<template>
  <div>
    <div class="main-page">
      <div class="main-page-info">
        <router-link to="/">
          <img src="img/HP-Main-Logo.svg" alt="" />
        </router-link>
        <h2 class="margin-bot">HelixPay Terms of Service</h2>
        <p style="margin-bottom: 1rem">Last Updated on June 21, 2021</p>
        <p style="margin-bottom: 1rem">
          BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE
          ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ AND
          UNDERSTOOD, AND, AS A CONDITION TO YOUR USE OF THE SERVICE, YOU AGREE
          TO BE BOUND BY, THE FOLLOWING TERMS AND CONDITIONS, INCLUDING
          HELIXPAY’S PRIVACY POLICY (TOGETHER, THESE “TERMS”) AND YOU REPRESENT
          THAT YOU HAVE THE AUTHORITY TO ENTER INTO THESE TERMS PERSONALLY OR ON
          BEHALF OF THE ENTITY YOU HAVE NAMED AS THE USER. THE TERM “YOU” REFERS
          TO THE INDIVIDUAL OR LEGAL ENTITY, AS APPLICABLE, IDENTIFIED AS THE
          USER DURING THE REGISTRATION PROCESS. IF YOU ARE NOT ELIGIBLE, OR DO
          NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE THE
          SERVICE. YOUR USE OF THE SERVICE, AND HELIXPAY’S PROVISION OF THE
          SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY HELIXPAY AND BY YOU TO BE
          BOUND BY THESE TERMS
        </p>

        <h2 style="margin-bottom: 1rem">HelixPay Service Overview:</h2>
        <p class="ninety">
          1. HelixPay provides users with certain hosted software to manage
          recurring revenues and subscription payments and orders, automated
          customer communications related to these payments and orders to
          improve customer retention, and other related services.
        </p>
        <p class="ninety margin-bot">
          2. User is solely responsible for all of the terms and conditions of
          the transactions conducted with your customers, including, without
          limitation, terms regarding payment, returns, warranties, shipping,
          handling, transportation, storage, insurance, fees, applicable taxes,
          and receipts, all of which must be in accordance with applicable law.
        </p>

        <h2 style="margin-bottom: 1rem">SECTION A: DEFINITIONS</h2>
        <p>
          <span><b>Provider: </b></span>HelixPay, the Provider of the HelixPay
          Platform.
        </p>
        <p>
          <span><b>Platform: </b></span>The combination of services and features
          provided by HelixPay across the merchant console and all versions of
          the system including the APIs, “no-code storefront”, and 3rd-party
          e-commerce integrations.
        </p>
        <p>
          <span><b>Service: </b></span>Providing access to and adequate usage of
          the Platform.
        </p>
        <p>
          <span><b>Client: </b></span>The business using HelixPay’s Platform.
        </p>
        <p>
          <span><b>Payment Processors: </b></span>Payment processing technology
          companies that HelixPay partners with to process payment transactions
          on the Platform.
        </p>
        <p>
          <span><b>Data: </b></span>All data, information, and content that is
          accessed in the Platform.
        </p>
        <p>
          <span><b>Customers: </b></span>The customers of the client who are
          paying for products and services via HelixPay and receiving automated
          communications from the Platform on behalf of the Client.
        </p>
        <p>
          <span><b>Storefront: </b></span>The unique web domain hosted by
          HelixPay on behalf of the Client where the Client can list their
          products and services available for purchase by Customers.
        </p>
        <p>
          <span><b>Products: </b></span>The products or services offered by the
          Client for purchase by Customers.
        </p>
        <p>
          <span><b>Subscription: </b></span>Recurring purchases or payments to
          be processed between Customers and the Client. HelixPay generally
          refers to any recurring payment as a subscription even if the Client,
          for instance a real estate company, does not use the term
          ‘subscription’ in their own communications with Customers.
        </p>
        <p>
          <span><b>Net Revenues: </b></span>The value of transactions paid from
          Customers to the Client on HelixPay after Platform Fees are deducted.
        </p>
        <p>
          <span><b>Platform Fees: </b></span>Fees charged by HelixPay to the
          Client for the use of the HelixPay Platform.
        </p>
        <p style="margin-bottom: 1rem">
          <span><b>Agreement: </b></span>This Service Agreement.
        </p>

        <h2 style="margin-bottom: 1rem">SECTION B: SERVICE DETAILS</h2>
        <p style="margin-bottom: 1rem; color: red">1. SERVICES</p>
        <p class="ninety">
          1.1 General: The Provider shall provide the Services:
        </p>
        <p class="eighty">
          a. in accordance with the Agreement and the laws of the Philippines;
        </p>
        <p class="eighty margin-bot">
          b. Following the guidelines set forth in this Agreement;
        </p>

        <p class="ninety margin-bot">
          1.2 Non-exclusive: The Provider’s provision of the Services to the
          Client is non-exclusive. Nothing in this Agreement prevents the
          Provider from providing the Services to any other person or entity.
        </p>

        <p class="ninety margin-bot">1.3 Availability:</p>
        <p class="eighty margin-bot">
          a. We will make reasonable efforts to keep the Service operational 24
          hours a day/7 days a week, except for: (i) planned downtime (of which
          we will use reasonable efforts to provide at least 72 hours prior
          notice, but in no event fewer than 8 hours prior notice); or (ii) any
          unavailability caused by circumstances beyond our control, including
          but not limited to, acts of God, acts of government, flood, fire,
          earthquakes, civil unrest, acts of terror, strikes or other labor
          problems or Internet service provider failures or delays, or failures
          or delays from the Platform’s Connected Technologies.
        </p>
        <p class="eighty margin-bot">
          b. Through the use of web services and APIs, the HelixPay Service
          interoperates with a range of third party service features (“Connected
          Technologies”). The Provider does not make any warranty or
          representation on the availability of those features, unless otherwise
          provided.
        </p>

        <p class="ninety margin-bot">
          1.4 Additional Features and Services: The Provider may, from time to
          time upon request or consent of the Client, make available additional
          features or services to the Platform to enhance the HelixPay Service.
          These future additions will be granted on the terms of this Agreement,
          subject to the Client paying any stated applicable Fees.
        </p>

        <p style="margin-bottom: 1rem; color: red">CLIENT OBLIGATIONS</p>
        <p class="ninety margin-bot">
          1.1 General use: The Client and its personnel must:
        </p>
        <p class="eighty margin-bot">
          a. use the Services in accordance with the Agreement solely for:
        </p>
        <p class="seventy">
          i. the Client’s own business purposes and the Client’s customers’
          personal purposes; and
        </p>
        <p class="seventy margin-bot">ii. lawful purposes; and</p>
        <p class="eighty margin-bot">
          b. not resell or make available the Services to any third party, or
          otherwise commercially exploit the Services, except as expressly
          permitted by the Agreement.
        </p>

        <p class="ninety margin-bot">
          1.2 Access conditions: When accessing the HelixPay Service, the Client
          and its personnel must:
        </p>
        <p class="eighty margin-bot">
          a. not impersonate another person or misrepresent authorization to act
          on behalf of others or the Provider.
        </p>
        <p class="eighty margin-bot">
          b. not attempt to undermine the security or integrity of the Connected
          Technologies;
        </p>
        <p class="eighty margin-bot">
          c. not use, or misuse, the HelixPay Service in any way which may
          impair the functionality of the Connected Technologies or impair the
          ability of any other user to use the HelixPay Service;
        </p>
        <p class="eighty margin-bot">
          d. not attempt to view, access or copy any material or data other than
          that to which the Client is authorized to access;
        </p>
        <p class="eighty margin-bot">
          e. neither use the HelixPay Service in a manner, nor transmit, input
          or store any Data, that breaches any third party right (including
          Intellectual Property Rights and privacy rights) or is objectionable,
          unlawful, incorrect or misleading; and
        </p>
        <p class="eighty margin-bot">
          f. comply with any terms of use on the Platform, as updated from time
          to time by the Provider.
        </p>

        <p class="ninety margin-bot">1.3 Permitted User:</p>
        <p class="eighty margin-bot">
          a. The Client may authorize any member of its personnel or a
          third-party to be a permitted user of the HelixPay Service, in which
          case the Client will provide the HelixPay with the permitted user’s
          name and other information that the Provider may require.
        </p>
        <p class="eighty margin-bot">
          b. The Client must procure each permitted user’s compliance with
          clauses 1.1 and 1.2 and any other reasonable condition notified by the
          Provider to the Client.
        </p>
        <p class="eighty margin-bot">
          c. A breach of any term of the Agreement by the Client or its
          permitted user is deemed to be a breach of the Agreement by the
          Client.
        </p>

        <p class="ninety margin-bot">
          1.4 Notice to Customers: Prior to making any Services available to a
          Customer, the Client shall inform the Customer on how the Provider
          collects, uses, holds and processes Personal Data obtained by it
          through the Customer’s use of the Services.
        </p>
        <p class="ninety margin-bot">
          1.5 Authorizations: The Client is responsible for procuring all
          licenses, authorizations and consents required for it and its
          personnel to use the Platform, including to input, use, store, process
          and distribute Data through the Services. The Provider shall be
          responsible for procuring all necessary licenses, permits, or
          clearances necessary for the Provider to allow the Client to use the
          Services, including but not limited to the Connected Technologies.
        </p>
        <p class="ninety margin-bot">
          1.6 Client Content: In order to operate the Service, HelixPay needs
          the right to make certain uses of your posted content uploaded into
          the Platform (for example, for your HelixPay Storefront, HelixPay
          Checkout or for automated messages to your Customers). Therefore, when
          you post content on the Platform, you agree that HelixPay may use and
          make available such content solely for the requirements of providing
          the Service. By posting content to the Service, you hereby represent
          and warrant that you have the right to post that content and to grant
          the foregoing rights to HelixPay. In addition, you agree that HelixPay
          may use your name and logo to identify you as a HelixPay customer on
          its website or in other sales or marketing materials (provided that
          HelixPay will not issue any press release regarding your use of the
          Service without your prior consent).
        </p>
        <p class="ninety margin-bot">
          1.7 Anonymized Data: Client acknowledges that Provider may (i)
          internally use (but not disclose) information and data collected by
          Provider regarding your use of the Service to create Aggregated
          Anonymous Data (as defined below), and (ii) may freely use and make
          available Aggregated Anonymous Data. " Aggregated Anonymous Data "
          means merchant-level information and data that is in an aggregated,
          anonymized form, and which can in no way be specifically linked to you
          or to any of your customers.
        </p>
        <p class="ninety margin-bot">
          1.8 Client products: The Client is solely and entirely responsible for
          the Client’s Products, including but not limited to any actions taken
          and/or any claims made by others related to the Client’s products, the
          development, operation, maintenance and compliance with all applicable
          laws of the Client's products, and all materials that appear on or
          within the Client’s products. Any concerns raised to the Provider
          regarding the Client’s Products may be communicated to the Client. The
          Client should not publicly reference the Provider as responsible for
          any quality concerns, defects, limitations or other complaints unless
          the concern is directly related to the HelixPay Service.
        </p>
        <p class="ninety margin-bot">
          1.9 Prohibited Use: Client is prohibited from using the Platform to
          facilitate and/or receive payments for the following purposes:
          narcotics, prohibited drugs and its identified precursors;
          unauthorized gaming; objectionable sexual materials such as child
          pornography; products infringing on any third party's copyright,
          patent, trademark, trade secret or other property rights or rights of
          publicity or privacy; or any other products or services prohibited by
          law. The Client agrees that any fees or fines charged to the Provider
          because of the Client’s sale of prohibited products or services will
          be chargeable to the Client.
        </p>
        <p class="ninety margin-bot">
          1.10 Use Risk: The Client’s use of the HelixPay Service is at the
          Client’s sole risk. The Client shall be solely responsible for any
          damage to the Client’s products or loss of data that results from the
          download or use of the Platform.
        </p>

        <p class="ninety margin-bot">1.11 Payment Concerns:</p>
        <p class="eighty margin-bot">
          a. Provider is not liable for any losses relating to chargebacks,
          fraudulent charges, or other actions by you or your customers that are
          deceptive, fraudulent or otherwise invalid (“Fraudulent Actions”). By
          using the Service, you hereby release HelixPay from any liability
          arising from Fraudulent Actions. You will also use best efforts to
          promptly notify Recurl y of any Fraudulent Actions which may affect
          the Service. HelixPay reserves the right, in its sole discretion, to
          terminate your account if you engage in, or permit any other user or
          customer to engage in, Fraudulent Actions.
        </p>
        <p class="eighty margin-bot">
          b. Client shall commit and ensure correct application of payment of
          customers. Client shall shoulder the risk and legal consequences of
          non- delivery or erroneous delivery of product/service,
          non-application or erroneous application of payment, or erroneous
          advice to refund.
        </p>
        <p class="eighty margin-bot">
          c. Between Provider and the Client, the Client shall shoulder the
          financial risk of error or fraud committed by its Customers,
          employees, and suppliers. This does not preclude the Client from
          exercising its right to avail of remedies under the law to recover any
          such amount from the liable parties. The Provider shall not be liable
          for reimbursements, payments, set-off or deductions for amounts which
          may have been erroneously or fraudulently obtained by such third
          persons.
        </p>

        <p style="margin-bottom: 1rem; color: red">2. DATA</p>
        <p class="ninety margin-bot">
          2.1 Provider access to Data. The Client acknowledges that the Provider
          may require access to Client Data to exercise its rights and perform
          its obligations under the Agreement; and to the extent that this is
          necessary but subject to the terms of this Agreement, the Provider may
          authorize a member or members of its personnel to access, collect,
          use, hold, process and/or destroy such Data for this purpose.
        </p>

        <p style="margin-bottom: 1rem; color: red">3. FEES</p>
        <p class="ninety margin-bot">3.1 Fees:</p>
        <p class="eighty margin-bot">
          a. The Provider will charge the Client a Platform Fee of 10% of the
          total payment amounts processed through the Platform. The Client will
          start with a P5,000 credit that will cover the Platform Fee until the
          credit balance is completed. There is no expiration on the credit.
        </p>
        <p class="eighty margin-bot">
          b. This fee is exclusive of Value Added Tax (VAT)
        </p>
        <p class="eighty margin-bot">
          c. In the case of any payment refunds returned to Customers by the
          Provider, the Platform Fee will not be refunded to the Client in order
          to cover the processing costs of the refund.
        </p>
        <p class="eighty margin-bot">
          d. The Provider will provide the Client with monthly summaries of the
          total transactions processed during the period and and total fees
          incurred during the period.
        </p>
        <p class="eighty margin-bot">
          e. As detailed in clause 7 of this Agreement, the Client may stop
          using HelixPay at any time without any cancellation fees or other
          additional costs.
        </p>

        <p style="margin-bottom: 1rem; color: red">4. SETTLEMENT TO CLIENT</p>
        <p class="ninety">4.1 Settlement to Client</p>
        <p class="eighty margin-bot">
          a. The Provider will transfer Net Revenues to the Client on a weekly
          basis. The settlement date will be on Fridays and will cover
          transactions occurring in the 7 day period ending on the most recent
          Wednesday. In the case a Settlement can not be completed within the
          day on Friday, the funds will be remitted on the following Monday or
          next possible day. From time to time Settlement may be delayed due to
          an outage, delay, restriction or other concern with one of our
          Connected Technologies, one of our Bank partners, or internally at
          HelixPay.
        </p>
        <p class="eighty margin-bot">
          b. Client will receive a summary report accompanying each weekly
          settlement.
        </p>
        <p class="eighty margin-bot">
          c. Client may select their preferred bank account to receive the
          settlement provided the bank is operational in the Philippines.
        </p>

        <p style="margin-bottom: 1rem; color: red">5. INTELLECTUAL PROPERTY</p>
        <p class="ninety margin-bot">
          5.1 Ownership: Title to, and all Intellectual Property Rights in, the
          Provider’s APIs, the Provider’s standard user interface, the Platform,
          and all Connected Technologies, are and remain the property of the
          Provider or the owner of the Connected Technologies. The Client must
          not dispute that ownership. The Provider grants the Client a
          worldwide, non-exclusive, revocable, royalty-free license to access
          and use the Provider’s APIs, the Provider’s standard user interface,
          the Platform, and all Connected Technologies during the effectivity of
          the Agreement solely for the purpose of and in accordance with the
          details set out in the Agreement’s Section C.
        </p>
        <p class="ninety margin-bot">
          5.2 Service Feedback: If the Client provides the Provider with ideas,
          comments or suggestions relating to the Services or Connected
          Technologies (together “Service Feedback”), all Intellectual Property
          Rights in that Service Feedback, and anything created as a result of
          that Service Feedback (including new material, enhancements,
          modifications or derivative works), are owned solely by the Provider.
          The Provider may use or disclose the Feedback for any purpose, subject
          to Section 6 on Confidentiality.
        </p>
        <p class="ninety margin-bot">
          5.3 Third party sites and material: The Client acknowledges that the
          Service, subject to prior written consent of the Client, may link to
          third party websites that are connected or relevant to the Service.
          Any link from the Service does not imply any Provider endorsement,
          approval or recommendation of, or responsibility for, those linked
          websites or their content. To the maximum extent permitted by law, the
          Provider excludes all responsibility or liability for those websites.
        </p>

        <p style="margin-bottom: 1rem; color: red">6. CONFIDENTIALITY</p>
        <p class="ninety margin-bot">
          6.1 Confidential Information: Confidential information is any
          information or data obtained by one party from the other in the course
          of performing their obligations under this Agreement. Any such
          disclosed and/or obtained information shall be deemed as confidential
          and/or proprietary information, and includes tangible, intangible,
          visual, electronic, present, or future information such as but not
          limited to: patent applications; trade secrets; financial information
          and data; technical information, including research, development,
          procedures, algorithms, data, designs, and know-how; business
          information, including operations, planning, marketing interests, and
          products; the terms or the fact of any agreement entered into,
          including between the Parties, and the discussions, negotiations and
          proposals related thereto; and any notes, analyses, compilations,
          studies or other material or documents prepared by a Party which
          contain, reflect or are based, in whole or in part, on the
          Confidential Information.
        </p>
        <p class="ninety margin-bot">
          6.2 Exclusions: Generally, all information disclosed by one Party to
          the other Party shall be considered as Confidential Information. For
          avoidance of doubt, information disclosed by one Party to the other
          Party shall not be considered as Confidential Information if they:
        </p>
        <p class="eighty margin-bot">
          a. were known to the Recipient before receipt from the Discloser;
        </p>
        <p class="eighty margin-bot">
          b. are or become publicly available through no fault of the Recipient;
        </p>
        <p class="eighty margin-bot">
          c. are rightfully received by the Recipient from a third party without
          a duty of confidentiality;
        </p>
        <p class="eighty margin-bot">
          d. are independently developed by the Recipient without a breach of
          this Agreement;
        </p>
        <p class="eighty margin-bot">
          e. are disclosed by the Recipient with the Discloser’s prior written
          approval; or
        </p>
        <p class="eighty margin-bot">
          f. if expressively marked as “not confidential” by the Discloser
        </p>

        <p class="ninety margin-bot">
          6.3 Security: Each party must, unless it has the prior written consent
          of the other party:
        </p>
        <p class="eighty margin-bot">
          a. keep confidential at all times the Confidential Information of the
          other party;
        </p>
        <p class="eighty margin-bot">
          b. effect and maintain adequate security measures to protect the other
          party’s Confidential Information from unauthorized access or use; and
        </p>
        <p class="eighty margin-bot">
          c. disclose the other party’s Confidential Information to its
          personnel or professional advisors on a need to know basis only and,
          in that case, ensure that any personnel or professional advisor to
          whom it discloses the other party’s Confidential Information is aware
          of, and complies with, the provisions of clauses 6.1a and 6.1b.
        </p>

        <p class="ninety margin-bot">
          6.4 Permitted disclosure: The obligation of confidentiality in clause
          6.1a does not apply to any disclosure or use of Confidential
          Information:
        </p>
        <p class="eighty margin-bot">
          a. for the purpose of performing the Agreement or exercising a party’s
          rights under the Agreement;
        </p>
        <p class="eighty margin-bot">b. required by law;</p>
        <p class="eighty margin-bot">
          c. which was rightfully received by a party to the Agreement from a
          third party without restriction and without breach of any obligation
          of confidentiality; or
        </p>
        <p class="eighty margin-bot">
          d. by the Provider if required as part of a bona fide sale of its
          business (assets or shares, whether in whole or in part) to a third
          party, provided that the Provider enters into a confidentiality
          agreement with the third party on terms no less restrictive than this
          clause 6.
        </p>

        <p style="margin-bottom: 1rem; color: red">7. WARRANTIES</p>
        <p class="ninety margin-bot">
          7.1 Mutual warranties: Each party warrants that it has full power and
          authority to enter into and perform its obligations under the
          Agreement which, when signed, will constitute binding obligations on
          the warranting party.
        </p>
        <p class="ninety margin-bot">
          7.2 Provider Warranties. Provider represents and warrants that it has
          the authority and the right to provide and perform the Services, and
          that its obligations hereunder are not in conflict with any other
          obligations; and that all Services will be performed in a competent
          and professional manner and shall conform in all material aspects as
          expressly set forth in this Agreement, the Statement of Work, and
          other documents signed by the Parties.
        </p>
        <p class="ninety margin-bot">
          7.3 Disclaimer of Warranties: Client agrees that Client uses the
          Service at their own risk. The Service is provided on an “As Is” and
          “As Available” basis. Provider makes no warranty that: the service
          will meet Client’s requirements or expectations, that Client’s access
          to or use of the Service will be uninterrupted, timely, secure or
          error free, and that any defects in the service will be corrected.
        </p>
        <p class="ninety margin-bot">
          7.4 No implied warranties: To the maximum extent permitted by law, the
          Provider’s warranties are limited to those set out in the Agreement,
          and all other conditions, guarantees or warranties whether expressed
          or implied by statute or otherwise are expressly excluded.
        </p>

        <p style="margin-bottom: 1rem; color: red">8. LIABILITY</p>
        <p class="ninety margin-bot">
          8.1 Maximum liability: The maximum aggregate liability of the Provider
          under or in connection with the Agreement or relating to the Services,
          whether in contract, tort (including negligence), breach of statutory
          duty or otherwise, must not in any period that the Agreement is
          effective exceed an amount equal to the Fees paid by the Client under
          the Agreement in the previous year (which in the first year is deemed
          to be the total Fees paid by the Client from the Start Date to the
          date of the first event giving rise to liability)
        </p>
        <p class="ninety margin-bot">
          8.2 Unrecoverable loss: Neither party is liable to the other under or
          in connection with the Agreement or the Services for any loss of
          profit, revenue, savings, business, use, data (including Data), and/or
          goodwill, or consequential, indirect, incidental or special damage, or
          loss of any kind caused by circumstances beyond a party’s control,
          including but not limited to, acts of God, acts of government, flood,
          fire, earthquakes, civil unrest, acts of terror, strikes or other
          labor problems or Internet service provider failures or delays, or
          failures or delays from the Platform’s Connected Technologies.
        </p>
        <p class="ninety margin-bot">8.3 Unlimited liability:</p>
        <p class="eighty margin-bot">
          a. Clauses 8.1 and 8.2 do not apply to limit the Provider’s liability:
        </p>
        <p class="seventy margin-bot">
          i. under or in connection with the Agreement for personal injury or
          death, fraud or wilful misconduct, or a breach of clause 7.
        </p>
        <p class="eighty margin-bot">
          b. Clause 8.2 does not apply to limit the Client’s liability:
        </p>
        <p class="seventy margin-bot">i. to pay the Fees; or</p>
        <p class="seventy margin-bot">
          ii. for those matters stated in clause 8.3a.i.
        </p>
        <p class="ninety margin-bot">
          8.4 No liability to the other: Subject to clauses 8.1 to 8.3, except
          for damages caused by the fault, misconduct or gross negligence of a
          party, the Provider or the Client shall not be liable to one another
          for any` claims or damages arising out of or relating to:
        </p>
        <p class="eighty margin-bot">
          a. Service interruptions, interaction or interconnection of the mobile
          service providers’ systems, or Client’s equipment and systems, with
          the Provider’s equipment and systems, whatsoever the cause of the
          interruption whether caused by or with applications, equipment,
          services or networks provided by Client, Provider, or by
          third-parties; or through unauthorized access to or by theft,
          alteration, loss or destruction of Provider’s applications, data,
          programs, information, network or systems through accident or
          fraudulent means.
        </p>
        <p class="eighty margin-bot">
          b. Communications/transactions that fail to reach their designated
          beneficiary, or any failure to properly deliver and complete
          communications/transactions intended for Customers, except where such
          failures occur because a party is at fault or has not complied
          strictly with all the proper instructions and procedures for the
          Service and all its obligations under this Agreement.
        </p>
        <p class="eighty margin-bot">
          c. Liabilities arising from usage of the Service beyond its known
          functionalities, limitations and capabilities.
        </p>
        <p class="ninety margin-bot">
          8.5 No liability to the other: Subject to clauses 8.1 to 8.3, except
          for damages caused by the fault, misconduct or gross negligence of a
          party, the Provider or the Client shall not be liable to one another
          for any` claims or damages arising out of or relating to:
        </p>
        <p class="ninety margin-bot">
          8.6 Mitigation: Each party must take reasonable steps to mitigate any
          loss or damage, cost or expense it may suffer or incur arising out of
          anything done or not done by the other party under or in connection
          with the Agreement.
        </p>
        <p class="ninety margin-bot">
          8.7 Excluded liability: The Provider is not liable to the Client under
          or in connection with this Agreement for any loss, liability or damage
          incurred by the Client in connection with any other warranty,
          representation or indemnity given by the Client to a Customer that is
          inconsistent with the terms of this Agreement.
        </p>
        <p class="ninety margin-bot">
          8.8 Indemnification: Client agrees to release, defend, indemnify, and
          hold Provider and its affiliates and subsidiaries, including but not
          limited to, its officers, directors, employees and agents, harmless
          from and against any claims, liabilities, damages, losses, and
          expenses, including, without limitation, legal and accounting fees,
          arising out of or in any way connected with (i) a Client’s breach of
          this Agreement, (ii) Client’s improper use of the platform, or (iv)
          Client’s breach of any laws, regulations or third party rights.
        </p>

        <p style="margin-bottom: 1rem; color: red">
          9. TERM, TERMINATION AND SUSPENSION
        </p>
        <p class="ninety margin-bot">
          9.1 Duration: Unless terminated under this clause 7, the Agreement:
        </p>
        <p class="eighty margin-bot">
          a. starts on the Start Date and shall run on a continuous basis
          thereafter unless terminated by either party by providing to the other
          party written notice of termination.
        </p>
        <p class="eighty margin-bot">
          b. shall be subject to periodic review, and may be modified or amended
          upon mutual consent of the parties. Any and all amendments to this
          Agreement shall be implemented only after the parties shall have
          mutually signified their conformity in writing.
        </p>
        <p class="ninety margin-bot">
          9.2 Consequences of termination or expiry:
        </p>
        <p class="eighty margin-bot">
          a. Termination or expiry of the Agreement does not affect either
          party’s rights and obligations that accrued before that termination or
          expiry.
        </p>
        <p class="eighty margin-bot">
          b. On termination or expiry of the Agreement, the Client must pay any
          outstanding Fees for Services provided prior to that termination or
          expiry. Late payments by Client shall incur an interest of 1% per
          month until fully paid.
        </p>
        <p class="eighty margin-bot">
          c. Except to the extent that a party has ongoing rights to use
          Confidential Information, at the other party’s request following
          termination or expiry of the Agreement, a party must promptly return
          to the other party or destroy all Confidential Information of the
          other party that is in the first party’s possession or control.
        </p>
        <p class="eighty margin-bot">
          d. At any time prior to one month after the date of termination or
          expiry, the Client may request:
        </p>
        <p class="seventy margin-bot">
          i. a copy of any Data stored using the HelixPay Service, provided that
          the Client pays the Provider’s reasonable costs of providing that
          copy. On receipt of that request, the Provider must provide a copy of
          the Data in a common electronic form. The Provider does not warrant
          that the format of the Data will be compatible with any software;
          and/or
        </p>
        <p class="seventy margin-bot">
          i. deletion of the Data stored using the HelixPay Service, in which
          case the Provider must use reasonable efforts to promptly delete that
          Data.
        </p>

        <p class="eighty margin-bot">
          c. Except to the extent that a party has ongoing rights to use
          Confidential Information, at the other party’s request following
          termination or expiry of the Agreement, a party must promptly return
          to the other party or destroy all Confidential Information of the
          other party that is in the first party’s possession or control.
        </p>
        <p class="eighty margin-bot">
          d. At any time prior to one month after the date of termination or
          expiry, the Client may request:
        </p>
        <p class="seventy margin-bot">
          i. a copy of any Data stored using the HelixPay Service, provided that
          the Client pays the Provider’s reasonable costs of providing that
          copy. On receipt of that request, the Provider must provide a copy of
          the Data in a common electronic form. The Provider does not warrant
          that the format of the Data will be compatible with any software;
          and/or
        </p>
        <p class="seventy margin-bot">
          i. deletion of the Data stored using the HelixPay Service, in which
          case the Provider must use reasonable efforts to promptly delete that
          Data.
        </p>
        <p class="eighty margin-bot">
          e. To avoid doubt, the Provider is not required to comply with clause
          9.2d.ii to the extent that the Client previously requested deletion of
          the Data.
        </p>
        <p class="ninety margin-bot">
          9.3 Obligations continuing: Clauses which, by their nature, are
          intended to survive termination or expiry of the Agreement, including
          clauses 5, 6, 8, and 10.3, continue in force.
        </p>
        <p class="ninety margin-bot">
          9.4 Suspending access: Without limiting any other right or remedy
          available to the parties, the Provider may restrict or suspend the
          Client’s access to the Service, even without prior notice, where:
        </p>
        <p class="eighty margin-bot">
          a. the Client (including any of its personnel) undermines, or attempts
          to undermine, the security or integrity of the Service or any
          Connected Technologies;
        </p>
        <p class="eighty margin-bot">
          b. the Client (including any of its personnel) uses, or attempts to
          use, the HelixPay Service:
        </p>
        <p class="seventy margin-bot">
          i. the Client (including any of its personnel) undermines, or attempts
          to undermine, the security or integrity of the Service or any
          Connected Technologies;
        </p>
        <p class="seventy margin-bot">
          i. in a manner, other than for normal operational purposes, that
          materially reduces the operational performance of the HelixPay
          Service; or
        </p>
        <p class="eighty margin-bot">
          c. the Client (including any of its personnel) has otherwise
          materially breached the Agreement
        </p>
        <p class="eighty margin-bot">
          d. The Provider deems a restriction or suspension or termination is in
          the best interest of the Platform or of Client’s Customers.
        </p>
        <p class="ninety margin-bot">
          9.5 Notice: The Provider must notify the Client as soon as practicable
          in advance where it restricts or suspends the Client’s access under
          clause 9.4.
        </p>

        <p style="margin-bottom: 1rem; color: red">10. DISPUTES</p>
        <p class="ninety margin-bot">
          10.1 Good faith negotiations: A party must use best efforts to resolve
          any dispute under, or in connection with, the Agreement through good
          faith negotiations before taking any court action. In case of
          persistent dispute, a party should inform the chief executive, or
          equivalent officer, of the other party.
        </p>
        <p class="ninety margin-bot">
          10.2 Obligations continue: Each party must, to the extent possible,
          continue to perform its obligations under the Agreement even if there
          is a dispute.
        </p>
        <p class="ninety margin-bot">
          10.3 Right to seek relief: This clause 10 does not affect either
          party’s right to seek urgent interlocutory and/or injunctive relief.
        </p>

        <p style="margin-bottom: 1rem; color: red">11. GENERAL</p>
        <p class="ninety margin-bot">
          11.1 Force Majeure: Neither party is liable to the other for any
          failure to perform its obligations under the Agreement to the extent
          caused by Force Majeure, provided that the affected party:
        </p>
        <p class="eighty margin-bot">
          a. immediately notifies the other party and provides full information
          about the Force Majeure;
        </p>
        <p class="eighty margin-bot">
          b. uses best efforts to overcome the Force Majeure; and
        </p>
        <p class="eighty margin-bot">
          c. continues to perform its obligations to the extent practicable.
        </p>
        <p class="ninety margin-bot">
          11.2 Rights of third parties: No person other than the Provider and
          the Client has any right to a benefit under, or to enforce, the
          Agreement.
        </p>
        <p class="ninety margin-bot">
          11.3 Waiver: To waive a right under the Agreement, that waiver must be
          in writing and signed by the waiving party.
        </p>
        <p class="ninety margin-bot">
          11.4 Notices: A notice given by a party under the Agreement must be
          delivered to the other party via email using the email address set out
          in Section A or otherwise notified by the other party for this
          purpose.
        </p>
        <p class="ninety margin-bot">
          11.5 Independent contractor: The Provider is an independent contractor
          of the Client, and no other relationship (e.g. joint venture, agency,
          trust or partnership) exists under the Agreement. Each party warrants
          that it shall not take action of any kind that is contrary to this
          express intention; and that if any such action is taken by any other
          person, each party shall hold each other free and harmless from all
          types of liability.
        </p>
        <p class="ninety margin-bot">
          11.6 Severability: Any illegality, unenforceability or invalidity of a
          provision of the Agreement does not affect the legality,
          enforceability or validity of the remaining provisions of the
          Agreement.
        </p>
        <p class="ninety margin-bot">
          11.7 Entire agreement: The Agreement sets out everything agreed by the
          parties relating to the Services. It supersedes and cancels anything
          discussed, exchanged or agreed prior to the Start Date. The parties
          have not relied on any representation, warranty or agreement relating
          to the subject matter of the Agreement that is not expressly set out
          in the Agreement, and no such representation, warranty or agreement
          has any effect from the Start Date.
        </p>
        <p class="ninety margin-bot">
          11.8 Variation: Any variation to the Agreement must be in writing and
          signed by both parties.
        </p>
        <p class="ninety margin-bot">
          11.9 Subcontracting and assignment: Either party may not assign,
          subcontract or transfer any right or obligation under the Agreement
          without the prior written consent of the other party. Such first party
          remains liable for its obligations under the Agreement despite any
          approved assignment, subcontracting or transfer. Any assignment,
          subcontracting or transfer must be in writing.
        </p>
        <p class="ninety margin-bot">
          11.10 Counterparts: The Agreement may be signed in counterparts, each
          of which constitutes an original and all of which constitute the same
          agreement. A party may enter the Agreement by signing and emailing a
          counterpart copy to the other party.
        </p>
        <p class="ninety margin-bot">
          11.11 Law and Venue: The Agreement is governed by, and must be
          interpreted in accordance with, the laws of the Philippines. Each
          party submits to the exclusive jurisdiction of the courts of Makati
          City, Philippines in relation to any dispute connected with the
          Agreement.
        </p>

        <h2 style="margin-bottom: 1rem">SECTION B: SERVICE DETAILS</h2>
        <p class="ninety margin-bot">
          1. To enable the Service, HelixPay providers the following:
        </p>
        <p class="eighty margin-bot">
          1.1 Client Account Creation and Account Services Clients register to
          use the HelixPay Platform from the HelixPay.ph website or via another
          registration portal provided by HelixPay. Upon complete registration,
          the Client gains access to their account.
        </p>
        <p class="eighty margin-bot">1.2 Platform Features</p>
        <p class="seventy margin-bot">
          a. Creation & Management of Storefront & Products Such as:
        </p>
        <p class="sixty margin-bot">i. Storefront design</p>
        <p class="sixty margin-bot">
          ii. Product names, photos, descriptions and configurations
        </p>
        <p class="sixty margin-bot">
          iii. Subscription settings such as payment types
        </p>
        <p class="sixty margin-bot">iv. Delivery fees per region</p>
        <p class="seventy margin-bot">
          b. Creation and Recurring Processing of Subscriptions Such as:
        </p>
        <p class="sixty margin-bot">
          i. Creation of new subscription for Customers
        </p>
        <p class="sixty margin-bot">
          ii. Automated recurrence of payment processing and payment reminders
        </p>
        <p class="sixty margin-bot">
          iii. Handling for failed payments and ability to edit payment methods
          by Customer and Client.
        </p>
        <p class="sixty margin-bot">
          iv. Processing Payments with Payment Processors
        </p>
        <p class="sixty margin-bot">
          v. Regular remittance of funds to Client’s bank account
        </p>
        <p class="seventy margin-bot">
          c. Automated Communications for Subscriptions & Client Marketing Such
          as:
        </p>
        <p class="sixty margin-bot">
          i. Automated messages via email, SMS, or other channels for reminders,
          confirmations, and alerts for upcoming payments, successful payments,
          and failed payments.
        </p>
        <p class="sixty margin-bot">
          ii. Confirmation messages in the cases of edited Subscriptions or
          Subscription payment selections.
        </p>
        <p class="sixty margin-bot">
          iii. Other communication tools provided by the Platform for customer
          engagement.
        </p>
        <p class="seventy margin-bot">
          d. Business Analytics & Financial Reports for Client Such as:
        </p>
        <p class="sixty margin-bot">
          i. Analytics on relevant business metrics related to the Client’s
          Subscriptions and Customers.
        </p>
        <p class="sixty margin-bot">
          ii. Summary reports for Client’s financial performance using the
          Platform during particular periods of time.
        </p>
        <p class="eighty margin-bot">
          1.3 Client Console Features Within the HelixPay Console, Clients can
          manage their Storefront and products, manage their customer records,
          order statuses, and access other information and features provided by
          the Platform.
        </p>
        <p class="eighty margin-bot">
          1.4 Client to Customer Interface & Features HelixPay provides the
          Client with multiple options to engage with their respective
          Customers. Clients access the Platform via the Console. Clients can
          offer HelixPay features to their customers via HelixPay APIs, via the
          HelixPay No-code Storefront, or via HelixPay Integrations to 3rd Party
          e-commerce platforms like Shopify and WooComerce.
        </p>
        <p class="seventy margin-bot">
          <b>a. HelixPay APIs</b> <br />
          allow the Client to integrate the HelixPay Checkout system and other
          features directly into the Client’s own technology platform.
        </p>
        <p class="sixty margin-bot">
          i. Access to HelixPay APIs are granted upon request by emailing
          support@helixpay.ph
        </p>
        <p class="sixty margin-bot">
          ii. Access to HelixPay API documentation can be found on the HelixPay
          website or by emailing support@helixpay.ph.
        </p>
        <p class="seventy margin-bot">
          <b>b. HelixPay No-code Storefront</b><br />
          The Platform provides each Client with their own configurable
          Storefront where Customers or prospective Customers can select the
          Products they want to include in their Subscription before proceeding
          to the HelixPay Checkout.
        </p>
        <p class="seventy margin-bot">
          <b>c. HelixPay Integrations to 3rd Party Storefront</b><br />
          The Platform enables the Client to use HelixPay 3rd party systems like
          Shopify, WooCommerce, and others.
        </p>
        <p class="ninety margin-bot">2. Support Touch Points:</p>
        <p class="eighty margin-bot">
          Client can get assistance from HelixPay in two primary ways:
        </p>
        <p class="seventy margin-bot">1. Email support@helixpay.ph</p>
        <p class="seventy margin-bot">
          2. HelixPay may also provide Client with a messaging service like
          Telegram or Facebook Messenger for support questions.
        </p>
        <p class="ninety margin-bot">3. Support Timing:</p>
        <p class="seventy margin-bot">
          1. Monday to Friday only, 9AM - 6PM (PH Time)
        </p>
        <p class="seventy margin-bot">
          2. Out of office hours support will be handled on a case by case basis
          depending on reported issue severity.
        </p>
        <p class="ninety margin-bot">
          HelixPay will continuously monitor the health of the HelixPay System
          to allow for preemptive prevention of critical and severe issues that
          may disrupt its availability.
        </p>

        <h2 style="margin-bottom: 1rem">SECTION E: DATA SHARING AGREEMENT</h2>
        <p class="ninety margin-bot">
          This Data Sharing Agreement (“DSA”’) sets out the specific Personal
          Data sharing arrangement for purposes of allowing the Provider to
          conduct its Services in accordance with the Agreement. Any Personal
          Data provided or shared shall be subject to the terms and conditions
          in this DSA and the overarching Agreement.
        </p>
        <p class="ninety margin-bot">Both parties agree and understand that:</p>
        <p class="eighty margin-bot">
          1. PURPOSE. The Client shall provide the Personal Data of its
          Customers, employees, and other Data Subjects (collectively “the Data
          Subjects”) for the purpose of allowing the Data Subjects to access the
          Services under the Client’s consent and instruction and enabling the
          Provider to perform the processing activities as provided for in this
          DSA and the overarching Agreement.
        </p>
        <p class="eighty margin-bot">
          2. COMPLIANCE WITH THE DATA PRIVACY ACT. The parties agree to provide
          a standard of protection to the Personal Data so transferred and
          received that is compliant with the standard of protection required
          under this Agreement as well as under Republic Act No. 10173,
          otherwise known as the Data Privacy Act of 2012 (the “DPA”), its
          Implementing Rules and Regulations (“IRR”), and all the existing
          circulars and/or guidelines that have been issued and shall be issued
          by the National Privacy Commission (“NPC”), and other data privacy
          laws as may be applicable (collectively, the “Data Privacy
          Legislation”). <br />
          The Provider shall make available to the Client all information
          necessary to demonstrate compliance with the obligations laid down in
          the Data Privacy Legislation.
        </p>
        <p class="eighty margin-bot">
          3. PURPOSE. The Client shall provide the Personal Data of its
          Customers, employees, and other Data Subjects (collectively “the Data
          Subjects”) for the purpose of allowing the Data Subjects to access the
          Services under the Client’s consent and instruction and enabling the
          Provider to perform the processing activities as provided for in this
          DSA and the overarching Agreement.
        </p>

        <p class="eighty margin-bot">4. OBLIGATIONS OF THE PARTIES</p>
        <p class="seventy margin-bot">
          4.1 The parties shall ensure that an obligation of confidentiality is
          imposed on persons authorized to process the Personal Data.
        </p>
        <p class="seventy margin-bot">
          4.2 The parties shall implement appropriate security measures and
          comply with the Data Privacy Legislation. The parties shall exercise
          at least the same degree of care as it uses with its own Personal Data
          and confidential information, but in no event less than reasonable
          care, to protect the Personal Data from misuse and unauthorized access
          or disclosure.
        </p>
        <p class="seventy margin-bot">
          4.3 The parties shall take appropriate decisions in performing the
          Services and hold any Personal Data securely in accordance with
          standards set out in the Agreement and the Data Privacy Legislation.
          Each party shall shall cooperate with one another to ensure the
          security and protection of Personal Data collected, processed,
          accessed, used and shared pursuant to the conduct of the Services, and
          assist one another to eliminate and/or mitigate any risk or effect of
          unauthorized, accidental or unlawful access, use or disclosure of
          Personal Data.
        </p>
        <p class="seventy margin-bot">
          4.4 The Provider shall inform the Client of any request from a Data
          Subject. The parties shall, to the extent possible, by appropriate
          technical and organizational measures, fulfill the obligation to
          respond to requests by Data Subjects relative to the exercise of their
          rights.
        </p>
        <p class="seventy margin-bot">
          4.5 At the choice of Client, Provider shall delete or return all
          Personal Data to it after the end of the provision of services
          relating to the Processing as contemplated in this Agreement; provided
          that this includes deleting existing copies unless storage is
          permitted by the Data Privacy Legislation or other relevant
          regulations.
        </p>
        <p class="seventy margin-bot">
          4.6 Provider shall immediately inform Client if, in its opinion, an
          instruction from Client infringes the Data Privacy Legislation.
        </p>
        <p class="seventy margin-bot">
          4.7 If the Provider is compelled by law or any legal process to
          disclose any Personal Data, it shall notify Client of such fact at the
          soonest possible opportunity but not more than twenty-four (24) hours
          from receipt of such notice or knowledge of the legal compulsion or
          process to disclose such Personal Data.
        </p>
        <p class="seventy margin-bot">
          4.8 The parties shall notify the relevant authorities, affected Data
          Subjects and affected party of any breach (or attempted breach) of
          Personal Data within 72 hours upon occurrence, knowledge, or discovery
          thereof, if such notification is required by applicable law.
        </p>

        <p class="eighty margin-bot">
          5. ORGANIZATIONAL, PHYSICAL AND TECHNICAL SECURITY MEASURES. The
          parties shall ensure that there are adequate organizational, physical
          and technical security measures in place for the processing of
          Personal Data of Data Subjects against any accidental, unauthorized or
          unlawful destruction, alteration, or disclosure, or against accidental
          loss or destruction, or against unauthorized or unlawful access,
          fraudulent misuse, or unlawful alteration and/or contamination. Such
          measures include the following and shall comply with the requirements
          of Data Privacy Legislation:
        </p>
        <p class="seventy margin-bot">
          5.1 Physical layout of the office spaces limits access to activities
          in the work area or workstations, including proper use of and access
          to electronic data and devices;
        </p>
        <p class="seventy margin-bot">
          5.2 Physical resources and infrastructure used in processing Personal
          Data are secured, as far as practicable, against mechanical
          destruction, natural disaster, power disturbances, external access and
          other similar threats;
        </p>
        <p class="seventy margin-bot">
          5.3 Technical security measures to protect shared Personal Data are
          employed as follows:
        </p>
        <p class="sixty margin-bot">
          a) Network security against accidental, unlawful or unauthorized usage
          that will affect data integrity or hinder the functioning or
          availability of the system, and/or unauthorized access;
        </p>
        <p class="sixty margin-bot">
          b) Maintain measures to ensure confidentiality, integrity,
          availability and resilience of Personal Data processing systems and
          services;
        </p>
        <p class="sixty margin-bot">
          c) Ability to restore the availability and access to Personal Data in
          a timely manner in the event of physical or technical incident;
          access;
        </p>
        <p class="sixty margin-bot">
          d) Regular testing, assessment and evaluation of the effectiveness of
          security measures;
        </p>
        <p class="sixty margin-bot">
          e) Control and limit access on digitally processed Personal Data to
          authorized persons through encryption whether data is at rest or while
          in transit, employ authentication process and other technical security
          measure;
        </p>
        <p class="sixty margin-bot">
          f) Encryption of data transferred via email or use of secure email
          facility with encryption feature for data including any attachments;
        </p>
        <p class="sixty margin-bot">
          g) Systems that scan outgoing emails and attachments for keywords that
          would indicate the presence of Personal Data, and as appropriate,
          prevent its transmission;
        </p>
        <p class="sixty margin-bot">
          h) Encryption of data in portable media used, such as USB drives, to
          store or transfer Personal Data and full disk encryption for laptops
          used to store Personal Data; and
        </p>
        <p class="sixty margin-bot">
          i) Use of identity authentication method that uses secured encrypted
          link for online access to process Personal Data.
        </p>

        <p class="eighty margin-bot" style="margin-bottom: 4rem">
          6. RIGHTS OF THE DATA SUBJECTS. In the Processing of Personal Data,
          the parties commit to respect and uphold the rights of Data Subjects.
        </p>
      </div>
    </div>

    <MainFooter />
  </div>
</template>

<script>
import MainFooter from '@/components/MainFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

export default {
  name: 'TermsAndConditions',
  components: {
    MainFooter,
  },

  setup() {
    useHead(
      buildPageMeta({
        title:
          'Terms and Conditions | Ecommerce Technology for Creators & Communities',
        description:
          'Select option that is the best fit for your business needs. HelixPay Checkout is custom built for subscriptions and recurring payments.',
        url: 'https://www.helixpay.ph/terms-conditions',
        image: 'https://www.helixpay.ph/img/helixpay_ticketing.jpg',
      })
    );
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  line-height: 2;
  box-sizing: border-box;
}

body {
  font-size: 1.1rem;
  line-height: 2;
}

.main-page {
  width: 100%;
}

.main-page-info {
  font-size: 1rem;
  line-height: 1.5;
  max-width: 60rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
}

.main-page-info img {
  width: 15rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

span {
  font-weight: 400;
}

.ninety {
  width: 90%;
  margin: auto;
  text-align: left;
}

.eighty {
  width: 80%;
  margin: auto;
  text-align: left;
}

.seventy {
  width: 70%;
  margin: auto;
  text-align: left;
}

.sixty {
  width: 60%;
  margin: auto;
  text-align: left;
}

.margin-bot {
  margin-bottom: 1rem;
}

p {
  opacity: 0.6;
  font-weight: 500;
}

@media only screen and (max-width: 1000px) {
  .main-page-info {
    font-weight: 300;
    font-size: 1rem;
  }
}
</style>
